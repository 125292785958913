import React, { useEffect, useState } from 'react'
import { throttle } from 'throttle-debounce'

import { UStoreProvider } from '@ustore/core'
import { t } from '$themelocalization'

import { Slot } from '$core-components'
import Layout from '../components/layout'
import CategoryItem from '../components/category/CategoryItem'
import ProductItem from '../components/products/ProductItem'
//import PromotionItem from '../components/products/PromotionItem'
import { Slider, Gallery } from '$core-components'

import { getIsNGProduct } from '$themeservices/utils'
import { decodeStringForURL } from '$ustoreinternal/services/utils'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import urlGenerator from '$ustoreinternal/services/urlGenerator'
import theme from '$styles/_theme.scss'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'

import './Home.scss'

const Home = (props) => {
  const { customState: { categories } } = props

  const [isMobile, setIsMobile] = useState(false)
  //const [promotionItemButtonUrl, setPromotionItemButtonUrl] = useState('')
  const [homeFeaturedProducts, setHomeFeaturedProducts] = useState(null)
  const [homeFeaturedCategory, setHomeFeaturedCategory] = useState(null)
  
  let transitionSpeedMS = parseInt(getVariableValue('--homepage-carousel-transition-speed').replace(/\D/g,""), 10) * 1000;
  let slideDurationMS = parseInt(getVariableValue('--homepage-carousel-slide-duration').replace(/\D/g,""), 10) * 1000;
  if (!transitionSpeedMS || transitionSpeedMS < 1000 || transitionSpeedMS > 5000)
      {transitionSpeedMS = 2000}
  if (!slideDurationMS || slideDurationMS < 3000 || slideDurationMS > 15000)
    {slideDurationMS = 6000}
  slideDurationMS = slideDurationMS + transitionSpeedMS;

  //const promotionItemImageUrl = getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/banner_image.png`), true)
  //const promotionItemTitle = getVariableValue('--homepage-carousel-slide-1-main-text', t('PromotionItem.Title'))
  //const promotionItemSubtitle = getVariableValue('--homepage-carousel-slide-1-sub-text', t('PromotionItem.Subtitle'))
  //const promotionItemButtonText = getVariableValue('--homepage-carousel-slide-1-button-text', t('PromotionItem.Button_Text'))
  
  let carouselContent = [
    {
      style: 'banner1',
      title: getVariableValue('--homepage-carousel-slide-1-main-text', t('slide1Heading')), 
      description: getVariableValue('--homepage-carousel-slide-1-sub-text',  t('slide1Subtitle')),
      button: getVariableValue('--homepage-carousel-slide-1-button-text',  t('slide1ButtonText')),
      url: getVariableValue('--homepage-carousel-slide-1-button-url', '/home/', false, true),
      color: {color: getVariableValue('--text-color-carousel') },
      image: getVariableValue('--homepage-carousel-slide-1-image', require(`$assets/images/POC_banner_image1.png`), true)
    }
  ]
  const slide2img = getVariableValue('--homepage-carousel-slide-2-image', require(`$assets/images/POC_banner_image2.png`), true)
  if (slide2img.length > 0)
    carouselContent.push({
      style: 'banner2',
      title: getVariableValue('--homepage-carousel-slide-2-main-text', t('slide2Heading')), 
      description: getVariableValue('--homepage-carousel-slide-2-sub-text',  t('slide2Subtitle')),
      button: getVariableValue('--homepage-carousel-slide-2-button-text',  t('slide2ButtonText')),
      url: getVariableValue('--homepage-carousel-slide-2-button-url', '/home/', false, true),
      color: {color: getVariableValue('--text-color-carousel2') },
      image: slide2img
    })
  const slide3img = getVariableValue('--homepage-carousel-slide-3-image', require(`$assets/images/POC_banner_image3.png`), true)
  if (slide3img.length > 0)
    carouselContent.push({
      style: 'banner3',
      title: getVariableValue('--homepage-carousel-slide-3-main-text', t('slide3Heading')), 
      description: getVariableValue('--homepage-carousel-slide-3-sub-text',  t('slide3Subtitle')),
      button: getVariableValue('--homepage-carousel-slide-3-button-text',  t('slide3ButtonText')),
      url: getVariableValue('--homepage-carousel-slide-3-button-url', '/home/', false, true),
      color: {color: getVariableValue('--text-color-carousel3') },
      image: slide3img
    })
    const slide4img = getVariableValue('--homepage-carousel-slide-4-image', '', true)
  if (slide4img.length > 0)
    carouselContent.push({
      style: 'banner4',
      title: getVariableValue('--homepage-carousel-slide-4-main-text', ''), 
      description: getVariableValue('--homepage-carousel-slide-4-sub-text',  ''),
      button: getVariableValue('--homepage-carousel-slide-4-button-text',  ''),
      url: getVariableValue('--homepage-carousel-slide-4-button-url', '', false, true),
      color: {color: getVariableValue('--text-color-carousel4') },
      image: slide4img
    })
  const slide5img = getVariableValue('--homepage-carousel-slide-5-image', '', true)
  if (slide5img.length > 0)
    carouselContent.push({
      style: 'banner5',
      title: getVariableValue('--homepage-carousel-slide-5-main-text', ''), 
      description: getVariableValue('--homepage-carousel-slide-5-sub-text',  ''),
      button: getVariableValue('--homepage-carousel-slide-5-button-text',  ''),
      url: getVariableValue('--homepage-carousel-slide-5-button-url', '', false, true),
      color: {color: getVariableValue('--text-color-carousel5') },
      image: slide5img
    })

  const onResize = () => setIsMobile(document.body.clientWidth < parseInt(theme.md.replace('px', '')))

  useEffect(() => {
    window.addEventListener('resize', onResize.bind(this))
    throttle(250, onResize);// Call this function once in 250ms only

    (async () => {
      const maxInPage = 200
      let { Count, Categories } = await UStoreProvider.api.categories.getTopCategories(1, maxInPage)
      if (Count === 0) {
        return
      }

      const page = Math.ceil(Count / maxInPage)
      if (page > 1) {
        const { Categories: lastPageCategories } = await UStoreProvider.api.categories.getTopCategories(page, maxInPage)
        if (lastPageCategories.length > 0) {
          Categories = lastPageCategories
        }
      }

      const homeFeaturedCategory = Categories[Categories.length - 1]
      const { Products } = await UStoreProvider.api.products.getProducts(homeFeaturedCategory.ID, 1)
      setHomeFeaturedProducts(Products)
      setHomeFeaturedCategory(homeFeaturedCategory)
    })()

    onResize()
    return () => {
      window.removeEventListener('resize', onResize)
      clearCustomState()
    }
  }, [])

  // useEffect(() => {
  //   if (categories && categories.length && !promotionItemButtonUrl) {
  //     const { FriendlyID, Name } = categories[0]
  //     const defaultURL = urlGenerator.get({ page: 'category', id: FriendlyID, name: decodeStringForURL(Name) })
  //     setPromotionItemButtonUrl(getVariableValue('--homepage-carousel-slide-1-button-url', defaultURL, false, true))
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.customState, categories])

  if (!props.state) {
    return null
  }

  return (
    <Layout {...props} className="home">
      <Slot name="homepage_top"/>
      <Carousel 
        autoPlay={true}        showThumbs={false} 
        infiniteLoop={true}    emulateTouch={true} 
        swipeable={true}       interval={slideDurationMS} 
        showArrows={true}      showStatus={false}
        showIndicators={true}  transitionTime={transitionSpeedMS}>
          {carouselContent.map((item, index) => (
                <div key={index}>
                  <img alt={item.title} src={item.image}></img>
                    <div className="carousel-overlay" style={item.color}>
                        <h1 className={item.style}>{item.title}</h1>
                        <p className={item.style}>{item.description}</p>
                        {item.url.length === 0 || item.button.length === 0
                          ? <span></span>
                          : item.url.substring(0,4).toLowerCase() === 'http'
                            ? <a className="button button-primary" href={item.url}>{item.button}</a>
                            : <Link to={item.url} className="button button-primary">{item.button}</Link>}
                    </div>
                </div>
            ))}
      </Carousel>
      {/*
      <div className="promotion-wrapper">
        <Slider>
          <PromotionItem
            imageUrl={promotionItemImageUrl}
            title={promotionItemTitle}
            subTitle={promotionItemSubtitle}
            buttonText={promotionItemButtonText}
            url={promotionItemButtonUrl}
          />
        </Slider>
      </div>
      */}
      <div className="middle-section">
        {categories && categories.length > 0 &&
          <div className="categories-wrapper">
            <Slider multi>
              {
                categories.map((model) => {
                    return <CategoryItem key={model.ID} model={model}
                                         url={urlGenerator.get({
                                           page: 'category',
                                           id: model.FriendlyID,
                                           name: decodeStringForURL(model.Name)
                                         })}/>
                  }
                )
              }
            </Slider>
          </div>
        }

        <div className="divider"/>
        {homeFeaturedCategory && homeFeaturedProducts?.length > 0 &&
          <div className="featured-products-wrapper">
            <Gallery title={homeFeaturedCategory.Name}
                     seeAllUrl={urlGenerator.get({
                       page: 'category',
                       id: homeFeaturedCategory.FriendlyID,
                       name: decodeStringForURL(homeFeaturedCategory.Name)
                     })}
                     gridRows="2">
              {
                homeFeaturedProducts.map((model) => {
                  const hideProduct =
                    isMobile &&
                    model.Attributes &&
                    model.Attributes.find(attr => attr.Name === 'UEditEnabled' && attr.Value === 'true') !== undefined

                  return !hideProduct &&
                    <ProductItem
                      key={model.ID}
                      model={model}
                      productNameLines="2"
                      descriptionLines="4"
                      url={getIsNGProduct(model)
                        ? urlGenerator.get({
                          page: 'products',
                          id: model.FriendlyID,
                          name: decodeStringForURL(model.Name)
                        })
                        : urlGenerator.get({
                          page: 'product',
                          id: model.FriendlyID,
                          name: decodeStringForURL(model.Name)
                        })
                      }
                    />
                })
              }
            </Gallery>
          </div>
        }
      </div>
      <Slot name="homepage_bottom"/>
    </Layout>
  )

}

export default Home

function clearCustomState () {
  UStoreProvider.state.customState.delete('homeFeaturedProducts')
  UStoreProvider.state.customState.delete('homeFeaturedCategory')
  UStoreProvider.state.customState.delete('currentProduct')
  UStoreProvider.state.customState.delete('currentOrderItem')
  UStoreProvider.state.customState.delete('currentOrderItemId')
  UStoreProvider.state.customState.delete('currentOrderItemPriceModel')
  UStoreProvider.state.customState.delete('lastOrder')
  UStoreProvider.state.customState.delete('currentProductThumbnails')
}
