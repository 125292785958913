import React, { Component } from "react"
import Layout from '../components/layout'
import './AboutUs.scss'
import { getVariableValue } from '$ustoreinternal/services/cssVariables'
import { t } from '$themelocalization'
import parse from 'html-react-parser';


class AboutUs extends Component {
  render() {
    const title = getVariableValue('--cust-aboutus-title', t('aboutusTitle'))
    const heading1 = getVariableValue('--cust-aboutus-section1-header', t('aboutusHeader1'))
    const heading2 = getVariableValue('--cust-aboutus-section2-header', t('aboutusHeader2'))
    const heading3 = getVariableValue('--cust-aboutus-section3-header', t('aboutusHeader3'))
    const text1 = parse(getVariableValue('--cust-aboutus-section1-text', t('aboutusText1')))
    const text2 = parse(getVariableValue('--cust-aboutus-section2-text', t('aboutusText2')))
    const text3 = parse(getVariableValue('--cust-aboutus-section3-text', t('aboutusText3')))

    return(
      <Layout {...this.props} className="AboutUs">
        <div>
          <div className="title">{title}</div>
          <div className="AboutUsHeading">{heading1}</div>
          <div className="AboutUsText">{text1}</div>
          <div className="AboutUsHeading">{heading2}</div>
          <div className="AboutUsText">{text2}</div>
          <div className="AboutUsHeading">{heading3}</div>
          <div className="AboutUsText">{text3}</div>
        </div>
      </Layout>
    )
  }
}
export default AboutUs