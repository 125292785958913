import contextService from '../services/context'

const httpPut = async (url, uploadedValue) => {
  const token = contextService.getValue('securityToken')
  const contentLanguage = contextService.getValue('languageCode')

  const myHeaders = new Headers()
  const formData = new FormData()
  myHeaders.append('Authorization', `uStore ${token}`)
  myHeaders.append('Accept-Language', contentLanguage)

  uploadedValue.map((item) => formData.append(encodeURIComponent(item.name), item, item.name))

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  }

  try {
    const response = await fetch(url, requestOptions)
    if (!response.ok) {
      throw new Error(`Server error: ${response.status}`);
    }
    return response
  } catch (e) {
    console.error('error uploading', e)
  }
}

export default httpPut
